<template>
  <div>
    <!-- <div>
      <button
        v-if="selectedLanguage === 'en'"
        @click="changeLanguage('ar')"
        class="bg-transparent text-primary fw-700"
      >
        العربية
        <img src="/assets/images/SA.svg" alt="">
      </button>
      <button
        v-if="selectedLanguage === 'ar'"
        class="bg-transparent text-primary fw-700"
        @click="changeLanguage('en')"
      >
        English
        <img src="/assets/images/US.svg" width="22px" height="12px" alt="">
      </button>
    </div> -->
    <div v-if="isLoading"></div> <!-- Loader -->
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale, setLocaleMessage } = useI18n();
const languages = [
  { code: 'en', name: 'English' },
  { code: 'ar', name: 'العربية' },
];

const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'ar');
const isLoading = ref(false); // Loading state

const setBodyDirection = (lang: string) => {
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  document.body.style.direction = direction;
  document.body.style.textAlign = lang === 'ar' ? 'right' : 'left';
};

// Updated changeLanguage function to accept language code as a parameter
const changeLanguage = async (lang: string) => {
  isLoading.value = true; // Set loading to true
 // Clear the existing locale cookie
 document.cookie = `i18n_redirected=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  try {
    const response = await fetch("https://api.cartrust.dev/api/v2/language-label", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ language: lang }),
    });

    const data = await response.json();
    if (!response.ok) throw new Error(`Server error: ${response.status}`);

    if (data && data.language_label) {
      const translations = data.language_label;
      setLocaleMessage(lang, translations);
      locale.value = lang;
      localStorage.setItem('selectedLanguage', lang);
      setBodyDirection(lang);
      selectedLanguage.value = lang; // Update the selected language
      console.log(`Language changed to: ${lang}`);
    } else {
      console.error(`No translations returned for language: ${lang}`);
    }
  } catch (error) {
    console.error("Error fetching language labels:", error);
  } finally {
    isLoading.value = false; // Set loading to false
  }
};

// Set the language and body direction when the component is mounted
onMounted(() => {
  setBodyDirection(selectedLanguage.value);
  changeLanguage(selectedLanguage.value);
});
</script>

<style scoped>
button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 5px;
}

button.active {
  background-color: #007bff; /* Active button color */
  color: white;
}

button:hover {
  background-color: #d3d3d3; /* Hover effect */
}
</style>
